<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="fromId"
          :items="fromOptions"
          @change="onFromChange"
          class="c-input-small"
          :label="$t('labels.from')"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model.number="keepQuantity"
          class="c-input-small"
          type="number"
          label="Giữ lại"
          append-icon="%"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="note"
          class="c-input-small"
          :label="$t('labels.note')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <SelectSuggestGoods
          :disabled="!fromId"
          :from-id="this.fromId"
          :from-type="this.fromType"
          @onSelect="selectItem"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          color="success"
          block
          @click="createTransfer"
          :disabled="isDisabledBtnSubmit"
          style="height: 40px"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-simple-table class="table-padding-2-no-top">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th
                  class="text-center vertical-align-middle"
                  style="width: 120px"
                >
                  Tỉnh thành
                </th>
                <th
                  class="text-center vertical-align-middle"
                  style="width: 120px"
                >
                  Chuyển tới
                </th>
                <th
                  class="text-center vertical-align-middle"
                  style="width: 70px"
                >
                  Trọng số
                </th>
                <th
                  class="text-center vertical-align-middle"
                  style="width: 140px"
                >
                  ĐVVC
                </th>
                <th class="text-center vertical-align-middle">Mã Vận Chuyển</th>
                <th
                  class="text-center vertical-align-middle"
                  style="width: 102px"
                >
                  <v-btn
                    color="primary"
                    x-small
                    class="mr-1"
                    @click="addConfig"
                    style="min-width: 30px; height: 30px; padding: 0"
                  >
                    <v-icon style="font-size: 24px">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    x-small
                    outlined
                    class="mr-1"
                    @click="showDialogConfig"
                    style="min-width: 30px; height: 30px; padding: 0"
                  >
                    <v-icon style="font-size: 24px">mdi-button-cursor</v-icon>
                  </v-btn>
                  <v-btn
                    color="success"
                    x-small
                    @click="beforeSaveConfig"
                    :disabled="configItems.length < 1"
                    style="min-width: 30px; height: 30px; padding: 0"
                  >
                    <v-icon style="font-size: 24px">mdi-content-save</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in configItems"
                :key="`s${item.id}`"
                class="text-center"
              >
                <td>
                  <v-autocomplete
                    v-model.number="item.id_city"
                    :items="cityOptions"
                    class="c-input-small"
                    label="Tỉnh thành"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-autocomplete>
                </td>
                <td>
                  <select-pos-model
                    v-model="item.id_pos"
                    :id-city="item.id_city"
                    :label="$t('labels.pos')"
                    single-line
                  ></select-pos-model>
                </td>
                <td>
                  <v-text-field
                    v-model.number="item.rate"
                    type="number"
                    class="c-input-small"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
                <td>
                  <v-autocomplete
                    v-model="item.id_delivery_company"
                    :items="deliveryCompanyOptions"
                    class="c-input-small"
                    label="ĐVVC"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-autocomplete>
                </td>
                <td>
                  <v-text-field
                    v-model="item.delivery_order_id"
                    :disabled="[-1].includes(item.id_delivery_company)"
                    class="c-input-small"
                    :label="$t('labels.delivery_order_id')"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
                <td>
                  <v-btn
                    color="error"
                    x-small
                    style="min-width: 20px; height: 20px; padding: 0"
                    @click="removeConfig(item)"
                  >
                    <v-icon style="font-size: 14px">mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" md="6">
        <v-simple-table class="table-padding-2-no-top">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.barcode") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.name") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.goods_description") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.size") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.available_quantity_1") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.quantity") }}
                </th>
                <th class="text-center vertical-align-middle">
                  {{ $t("labels.price_0") }}
                </th>
                <th class="text-center vertical-align-middle">
                  <v-btn
                    v-if="selectItems.length > 0"
                    color="warning"
                    x-small
                    style="min-width: 20px; height: 20px; padding: 0"
                    @click="resetSelected"
                  >
                    <v-icon style="font-size: 14px">mdi-restore</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody v-if="cqSelectedItems.length > 0">
              <tr
                v-for="item in cqSelectedItems"
                :key="`s${item.id}`"
                class="text-center"
              >
                <td style="width: 105px">{{ item.customer_goods_barcode }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.size }}</td>
                <td>{{ formatNumber(item.available) }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ formatNumber(item.price) }}</td>
                <td>
                  <v-btn
                    color="error"
                    x-small
                    style="min-width: 20px; height: 20px; padding: 0"
                    @click="removeSelected(item)"
                  >
                    <v-icon style="font-size: 14px">mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="9" class="text-center vertical-align-middle">
                  {{ $t("labels.not_yet_select_goods") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogConfig" persistent max-width="400px">
      <SelectConfigTransferByRate
        v-if="dialogConfig"
        @close="hideDialogConfig"
        @onSelect="selectConfig"
      />
    </v-dialog>

    <v-dialog v-model="dialogBeforeSave" persistent max-width="400px">
      <v-card>
        <v-card-title class="text-h6">
          Lưu cấu hình
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogBeforeSave = false">
            {{ $t("labels.close") }}
          </v-btn>
          <v-btn color="success darken-1" text @click="saveConfig">
            {{ $t("labels.save") }}
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div>
            <v-checkbox
              v-model="isCreateConfig"
              label="Lưu cấu hình mới"
              :disabled="!configSelected.id"
            ></v-checkbox>
          </div>
          <div>
            <v-text-field
              v-model="configSelected.name"
              class="c-input-small"
              placeholder="Tên cấu hình"
              label="Tên cấu hình"
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "TransferByRate",
  components: {
    SelectSuggestGoods: () => import("@/components/common/SelectSuggestGoods"),
    SelectConfigTransferByRate: () =>
      import("@/components/pos_goods/SelectConfigTransferByRate"),
  },
  data: () => ({
    isLoading: false,
    fromToOptions: [],
    fromType: null,
    fromId: null,
    keepQuantity: null,
    note: null,
    deliveryCompanyOptions: [],
    cityOptions: [],
    selectItems: [],
    configSelected: {
      id: null,
      name: null,
    },
    configItems: [],
    isCreateConfig: true,
    dialogConfig: false,
    dialogBeforeSave: false,
  }),
  computed: {
    isDisabledBtnSubmit() {
      return (
        !this.fromType ||
        !this.fromId ||
        this.configItems.length === 0 ||
        this.selectItems.length === 0
      );
    },
    fromOptions() {
      return [...this.fromToOptions].filter((i) => i.type === "warehouse");
    },
    cqSelectedItems() {
      return [...this.selectItems].map((item) => {
        const quantity =
          item.available -
          Math.ceil((item.available * (this.keepQuantity || 0)) / 100);
        return {
          ...item,
          quantity: quantity > 0 ? quantity : 0,
        };
      });
    },
    totalRate() {
      let total = 0;
      for (let i = 0; i < this.configItems.length; i++) {
        total += this.configItems[i].rate;
      }
      return total;
    },
    orderConverted() {
      const orders = [];
      const dataCreate = {};
      for (let i = 0; i < this.configItems.length; i++) {
        const configItem = this.configItems[i];
        for (let s = 0; s < this.cqSelectedItems.length; s++) {
          const item = this.cqSelectedItems[s];
          if (!dataCreate[configItem.id_pos]) {
            dataCreate[configItem.id_pos] = [];
          }
          const pos_quantity = Math.floor(
            (item.quantity * configItem.rate) / this.totalRate
          );
          if (pos_quantity > 0) {
            dataCreate[configItem.id_pos].push({
              ...item,
              barcode: item.customer_goods_barcode,
              quantity: pos_quantity,
            });
          }
        }
      }
      const keys = Object.keys(dataCreate);
      for (let k = 0; k < keys.length; k++) {
        const key = +keys[k];
        const item = [...this.configItems].find((si) => si.id_pos === key);
        if (dataCreate[key] && dataCreate[key].length > 0) {
          orders.push({
            to_type: "pos",
            to_id: item.id_pos,
            note: this.note,
            tt: k + 1,
            id_delivery_company: item.id_delivery_company,
            delivery_order_id: item.delivery_order_id,
            tvc: item.id_delivery_company !== -1 ? 1 : 0,
            goods_items: dataCreate[key],
          });
        }
      }
      return orders;
    },
  },
  watch: {},
  created() {},
  mounted() {
    const { warehouses, poss } = window.me;
    const warehouseOptions = [...warehouses].map((w) => ({
      value: w.id,
      text: `Kho: ${w.code_name || ""}`,
      type: "warehouse",
    }));
    const posOptions = [...poss].map((p) => ({
      value: p.id,
      text: `CH: ${p.code || ""}`,
      type: "pos",
    }));
    this.fromToOptions = [...warehouseOptions, ...posOptions];

    this.getDeliveryCompany();
    this.getCities();
  },
  methods: {
    async getDeliveryCompany() {
      const { data } = await httpClient.post("/get-delivery-company");
      const options = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
      options.unshift({
        value: -1,
        text: "SSC",
      });
      this.deliveryCompanyOptions = [...options];
    },
    async getCities() {
      const { data } = await httpClient.post("/get-city-by-my-pos");
      this.cityOptions = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    onFromChange() {
      this.items = [];
      this.selectItems = [];
      const fromItem = [...this.fromToOptions].find(
        (i) => i.value === this.fromId
      );
      if (!fromItem) {
        return false;
      }
      this.fromType = fromItem.type;
    },
    async createTransfer() {
      if (!this.fromId || !this.fromType) {
        this.$vToastify.error(this.$t("messages.wh_not_selected"));
        return false;
      }
      if (this.keepQuantity === null) {
        this.$vToastify.error(this.$t("messages.percent_kept_empty"));
        return false;
      }
      if (this.cqSelectedItems.length === 0) {
        this.$vToastify.error(this.$t("messages.product_not_selected"));
        return false;
      }
      if (this.configItems.length === 0) {
        this.$vToastify.error(this.$t("messages.config_not_selected"));
        return false;
      }
      const isValidConfig = this.validateConfig();
      if (!isValidConfig) {
        return false;
      }

      if (this.orderConverted.length === 0) {
        this.$vToastify.error(
          this.$t("messages.not_enough_inventory_to_allocate")
        );
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        let fd = new FormData();
        fd.append("orders", JSON.stringify(this.orderConverted));
        fd.append("from_type", this.fromType);
        fd.append("from_id", this.fromId);
        await httpClient.post("/transfer-create", fd);
        this.selectItems = [];
        this.configItems = [];
        this.configSelected = { id: null, name: null };
        this.keepQuantity = null;
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || {
          errRows: [],
          errTexts: [],
        };
        if (
          validates &&
          validates.errGroups &&
          validates.errGroups[0] &&
          validates.errGroups[0].errors
        ) {
          const errors = validates.errGroups[0].errors || [];
          this.$vToastify.error(errors.join("; "));
        } else {
          this.$vToastify.error(errMsg);
        }
      }
    },
    selectItem(items) {
      this.selectItems = [...items];
    },
    removeSelected(item) {
      this.selectItems = [...this.selectItems].filter((s) => s.id !== item.id);
    },
    addConfig() {
      this.configItems.push({
        id: this.generateRandomString(6),
        id_pos: null,
        rate: null,
        id_delivery_company: null,
      });
    },
    removeConfig(item) {
      this.configItems = [...this.configItems].filter((s) => s.id !== item.id);
    },
    resetSelected() {
      this.selectItems = [];
    },
    validateConfig() {
      const checkNulls = [...this.configItems].filter(
        (s) => !s.id_pos || !s.rate || !s.id_delivery_company
      );
      if (checkNulls.length > 0) {
        this.$vToastify.error(this.$t("messages.pos_info_not_enough"));
        return false;
      }

      const checkDuplicates = [...this.configItems].filter(
        (s) =>
          this.configItems.filter(
            (si) => si.id_pos === s.id_pos && si.id !== s.id
          ).length > 0
      );
      if (checkDuplicates.length > 0) {
        this.$vToastify.error("1 Điểm bán không thể có nhiều hơn 1 tỷ trọng");
        return false;
      }
      return true;
    },
    beforeSaveConfig() {
      const isValid = this.validateConfig();
      if (!isValid) {
        return false;
      }
      this.dialogBeforeSave = true;
    },
    async saveConfig() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/pos-save-goods-transfer-rate-config",
          {
            id: this.isCreateConfig ? null : this.configSelected.id,
            name: this.configSelected.name,
            items: this.configItems,
          }
        );
        this.isLoading = false;
        this.dialogBeforeSave = false;
        this.configSelected = { ...this.configSelected, id: data };
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
      }
    },
    showDialogConfig() {
      this.dialogConfig = true;
    },
    hideDialogConfig() {
      this.dialogConfig = false;
    },
    selectConfig(item) {
      const configSelected = { id: item.id, name: item.name };
      const configItems = [...item.items];
      this.configSelected = { ...configSelected };
      this.configItems = [...configItems];
      this.hideDialogConfig();
    },
    quantityChange: debounce(function () {
      this.selectItems = [...this.selectItems];
    }, 500),
  },
};
</script>

<style scoped></style>
